<template>
  <div style="padding: 96px 0px;">
    <section-headed-description
      :title="title"
      icon=""
      :description="slideTitle"
      style="padding: 96px 0 24px"
    />
    <v-carousel
      v-model="currentSlide"
      show-arrows-on-hover
      hide-delimiters
      height="auto"
      class="height-carousel pb-12"
      @change="setTitle"
    >
      <v-carousel-item
        v-for="(item,i) in gallery"
        :key="i"
      >
        <v-img
          :src="getImgFromCollectionUrl(item)"
          :contain="true"
          max-height="600px"
        />
      </v-carousel-item>
    </v-carousel>
    <div class="slick ">
      <slick
        ref="slick"
        class="pt-12"
        :options="slickOptions"
        @afterChange="handleAfterChange"
      >
        <div
          v-for="(item,i) in gallery"
          :key="i"
        >
          <base-img
            class="pointer"
            :src="getImgFromCollectionUrl(item)"
            max-height="120px"
          />
        </div>
      </slick>
    </div>
  </div>
</template>

<script>
  import SectionHeadedDescription from '@/views/sections/reusable/HeadedDescription'
  import { STORE_NAMESPACE_REALIZATIONS } from '@/views/realizations/Index.vue'
  import getImgFromCollectionUrl from '@/utils/getImgFromCollectionUrl.js'
  import Slick from 'vue-slick'
  import 'slick-carousel/slick/slick.css'
  import { mapState } from 'vuex'
  import SectionTrustedUs from '../TrustedUs'

  export default {
    name: 'SectionSlider',
    components: {
      SectionTrustedUs,
      SectionHeadedDescription,
      Slick,
    },
    props: {
      titled: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
      },
      gallery: {
        type: Array,
      },
    },
    computed: {
      ...mapState(STORE_NAMESPACE_REALIZATIONS, [
        'translations',
      ]),
      slideTitle () {
        return this.titled && this.gallery ? this.gallery[this.currentSlide].title : `${this.currentSlide + 1}`
      },
      language () {
        return this.$store.state.language
      },
      maxHeight () {
        return this.$vuetify.breakpoint.width >= this.mobileBreakPoint ? 'auto' : '600px'
      },
      slickOptions () {
        return {
          slidesToShow: this.gallery.length > 10 ? 10 : this.gallery.length,
          slidesToScroll: 1,
          arrows: false,
          focusOnSelect: true,
          pauseOnHover: true,
          initialSlide: (this.gallery.length / 2).toFixed(),
          centerMode: true,
          adaptiveHeight: true,
          centerPadding: this.$vuetify.breakpoint.mdAndUp ? '400px' : '16px',
        }
      },
    },
    watch: {
      gallery: function () {
        this.$refs.slick.destroy()
        this.$nextTick(() => {
          this.$refs.slick.create()
        })
      },
    },
    methods: {
      getImgFromCollectionUrl,
      handleAfterChange (event, slick, currentSlide) {
        this.currentSlide = currentSlide
      },
      setTitle (slideNumber) {
        this.$refs.slick.goTo(slideNumber)
      },
    },
    data () {
      return {
        currentSlide: 0,
      }
    },
  }
</script>

<style lang="scss">
  .slick{
    margin-top: -65px;
    padding-bottom: 15px;
    background: #595457;
    .slick-slide {
      border: 10px solid transparent;
      .v-image__image{
      }
      &>div{
        &>div{
          display: block!important;
        }
      }
    }
    .slick-current {
      width: auto;
      background: #e12b31;
    }
  }
  .slick-slider .slick-track, .slick-slider .slick-list{
    transform: none!important;
  }
</style>
<!--primary: '#e12b31',-->
<!--secondary: '#595457',-->
<!--accent: '#EADEDA',-->
